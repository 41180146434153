import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '@/views/auth/Login.vue'),
    meta:{
      isAuth: true
    }   
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
  },
  {
    path: '/pagos',
    name: 'payments',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Payment.vue')
  },
  {
    path: '/polls',
    name: 'polls',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Poll.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Landing.vue')
  },
  {
    path: '/encuesta-satisfaccion',
    name: 'poll',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Poll.vue')
  },

  //------------------------ Admin views ---------------------------------------------
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "about" */ '@/views/admin/Admin.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/list-forms',
    name: 'list-forms',
    component: () => import(/* webpackChunkName: "about" */ '@/views/admin/ListForms.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/patients/',
    name: 'create-patient',
    component: () => import(/* webpackChunkName: "about" */ '@/views/admin/patients/CreatePatient.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/patients/',
    name: 'list-patients',
    component: () => import(/* webpackChunkName: "about" */ '@/views/admin/patients/ListPatients.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/payments',
    name: 'create-payment',
    component: () => import(/* webpackChunkName: "about" */ '@/views/admin/payments/CreatePayment.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/payments',
    name: 'list-payments',
    component: () => import(/* webpackChunkName: "about" */ '@/views/admin/payments/ListPayments.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/diaries',
    name: 'create-diary',
    component: () => import(/* webpackChunkName: "about" */ '@/views/admin/diaries/CreateDiary.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/diaries',
    name: 'list-diaries',
    component: () => import(/* webpackChunkName: "about" */ '@/views/admin/diaries/ListDiaries.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/procedures',
    name: 'create-procedure',
    component: () => import(/* webpackChunkName: "about" */ '@/views/admin/procedures/CreateProcedure.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/procedures',
    name: 'list-procedures',
    component: () => import(/* webpackChunkName: "about" */ '@/views/admin/procedures/ListProcedures.vue'),
    meta: {
      requiresAuth: true
    }
  },
  //----------------------------------------------------------- Admin user
  {
    path: '/admin/users',
    name: 'list-users',
    component: () => import(/* webpackChunkName: "about" */ '@/views/admin/users/ListUsers.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/users',
    name: 'create-user',
    component: () => import(/* webpackChunkName: "about" */ '@/views/admin/users/CreateUser.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/users',
    name: 'edit-user',
    component: () => import(/* webpackChunkName: "about" */ '@/views/admin/users/EditUser.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/users',
    name: 'permissions-user',
    component: () => import(/* webpackChunkName: "about" */ '@/views/admin/users/PermissionsUser.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/users',
    name: 'create-rol',
    component: () => import(/* webpackChunkName: "about" */ '@/views/admin/users/CreateRol.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '*',
    name: 'error404',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Error404.vue')
  },
]

const router = new VueRouter({
  routes,
  mode:'history'
})

router.beforeEach((to, from, next)=>{
  if(to.meta.requiresAuth){
      if(!localStorage.getItem('key')){
          next({
              name: 'login'
          });
      }else{
          next()
      }
  }else{
      next();
  }

  if(to.meta.isAuth){
      if(localStorage.getItem('key')){
          next({
              name: 'home'
          });
      }else{
          next()
      }
  }else{
      next()
  }
});

export default router
